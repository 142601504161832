<template>
    <div class="bg-mafia-dark flex-column position-relative">
        <div class="flex-1 flex-column justify-center">
            <div><img :src="require('@/assets/image/web/ico_logo_large.svg')" /></div>
            <div class="mt-20">
                <img :src="require('@/assets/image/web/ico_logo_word.svg')" />
            </div>
            <div class="mt-20">문화예술 전용 커뮤니티 <b>마피아 프로젝트</b></div>
        </div>

        <div class="intro_btn_wrap pa-30">
            <button class="btn btn-primary radius-25" @click="$emit('to', { name: 'login' })">로그인</button>
            <button class="mt-20 color-white" @click="$emit('to', { name: 'agree' })">회원가입</button>
            <p class="copyright mt-20 color-888">Copyright © MAFI Inc.</p>
        </div>
    </div>
</template>

<script>
export default {
	name: 'mafia003'
	,data: function(){
        return {
            program: {
                name: '로그인'
                , not_header: true
                , not_footer: true
                , type: 'auth'
            }
        }
    }
    ,methods: {

    }
    ,created() {
        this.$emit('onLoad', this.program)
    }
}
</script>

<style>
.container-auth {
    width: 50rem;
}

.intro_btn_wrap {
		position: absolute; bottom: 0; left: 0;
        width: 100%;
}
.intro_btn_wrap button:nth-child(1) {
    padding: 14px 0;
    font-size: 16px;
    font-weight: 500;
}
.intro_btn_wrap button:nth-child(2) {
    font-size: 14px;
    font-weight: 500;
}
</style>
